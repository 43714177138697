import React, { useEffect, useState } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

const DarkerHumanitarianMap = () => {
  const [lat, setLatitude] = useState();
  const [long, setLongitude] = useState();
  const defaultLocation = [79.40618, 22.543985];
  useEffect(() => {
    // Initialize the map
    const map = new maplibregl.Map({
      container: "map", // The ID of the container where the map will render
      style:
        "https://api.maptiler.com/maps/streets/style.json?key=OvbgjQ8SxmuUHaVhDBU9", // Default MapLibre style
      zoom: 8, // Initial zoom level
    });
    // Function to get user's location
    const setUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLatitude(latitude);
            setLongitude(longitude);
            // Update map center and add marker
            map.setCenter([longitude, latitude]);
            map.setZoom(11);

            const markerDiv = document.createElement("div");
            markerDiv.style.backgroundColor = "red";
            markerDiv.style.width = "20px";
            markerDiv.style.height = "20px";
            markerDiv.style.borderRadius = "50%";

            // Create tooltip element
            const tooltipDiv = document.createElement("div");
            tooltipDiv.innerText = "Your Current Location";
            tooltipDiv.style.position = "absolute";
            tooltipDiv.style.bottom = "30px";
            tooltipDiv.style.left = "50%";
            tooltipDiv.style.transform = "translateX(-50%)";
            tooltipDiv.style.padding = "5px 10px";
            tooltipDiv.style.backgroundColor = "#fff";
            tooltipDiv.style.color = "#000";
            tooltipDiv.style.border = "1px solid #ccc";
            tooltipDiv.style.borderRadius = "5px";
            tooltipDiv.style.fontSize = "12px";
            tooltipDiv.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
            tooltipDiv.style.textAlign = "center";
            tooltipDiv.style.whiteSpace = "nowrap";

            // Create arrow using ::after
            const arrowDiv = document.createElement("div");
            arrowDiv.style.content = "";
            arrowDiv.style.position = "absolute";
            arrowDiv.style.bottom = "-5px";
            arrowDiv.style.left = "50%";
            arrowDiv.style.transform = "translateX(-50%)";
            arrowDiv.style.width = "0";
            arrowDiv.style.height = "0";
            arrowDiv.style.borderLeft = "5px solid transparent";
            arrowDiv.style.borderRight = "5px solid transparent";
            arrowDiv.style.borderTop = "5px solid #fff";

            // Append arrow to tooltip
            tooltipDiv.appendChild(arrowDiv);

            // Append tooltip to marker
            markerDiv.appendChild(tooltipDiv);

            new maplibregl.Marker({ element: markerDiv })
              .setLngLat([longitude, latitude])
              .addTo(map);

            // Restrict the map's interactions to a bounding box
            const bounds = [
              [longitude - 0.5, latitude - 0.3], // Southwest corner
              [longitude + 0.5, latitude + 0.3], // Northeast corner
            ];
            map.setMaxBounds(bounds);
          },
          (error) => {
            console.warn("Geolocation error:", error.message);

            // Handle different geolocation errors
            if (error.code === error.PERMISSION_DENIED) {
              alert(
                "Location permission denied. Showing the default location instead."
              );
            } else if (error.code === error.POSITION_UNAVAILABLE) {
              alert(
                "Location information is unavailable. Using default location."
              );
            } else if (error.code === error.TIMEOUT) {
              alert("Location request timed out. Using default location.");
            }

            // Fallback to default location
            map.setCenter(defaultLocation);
            map.setZoom(4);
            setLatitude(defaultLocation[1]);
            setLongitude(defaultLocation[0]);
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        alert("Geolocation is not supported. Showing the default location.");
        map.setCenter(defaultLocation);
        map.setZoom(4);
        setLatitude(defaultLocation[1]);
        setLongitude(defaultLocation[0]);
      }
    };

    // Add WMS source (replace URL with your WMS service)
    map.on("load", () => {
      // Call the function to set user's location
      setUserLocation();
      if (lat && long) {
        // Define restricted bounds (London area)
        const bounds = [
          [long - 0.1, lat - 0.08], // Southwest corner
          [long + 0.1, lat + 0.08], // Northeast corner
        ];
        // Draw the restricted area rectangle
        map.addLayer({
          id: "restricted-rectangle",
          type: "fill",
          source: {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [
                  [
                    [long - 0.1, lat - 0.08], // Southwest corner
                    [long + 0.1, lat - 0.08], // Southeast corner
                    [long + 0.1, lat + 0.08], // Northeast corner
                    [long - 0.1, lat + 0.08], // Northwest corner
                    [long - 0.1, lat - 0.08], // Closing the polygon
                  ],
                ],
              },
            },
          },
          paint: {
            "fill-color": "rgba(255, 255, 255, 0)", // Semi-transparent red
            "fill-outline-color": "rgba(255, 0, 0, 1)", // Red border
          },
        });
      }
    });

    // Restrict interactions to the defined bounds (London area)
    map.on("click", (e) => {
      const { lngLat } = e;
      const bounds = [
        [long - 0.1, lat - 0.08], // Southwest corner
        [long + 0.1, lat + 0.08], // Northeast corner of the bounding box (London area)
      ];

      if (
        lngLat.lng < bounds[0][0] ||
        lngLat.lng > bounds[1][0] ||
        lngLat.lat < bounds[0][1] ||
        lngLat.lat > bounds[1][1]
      ) {
        alert("You don't have access to this part of the map yet.");
      }
    });

    return () => map.remove(); // Cleanup map on component unmount
  }, [lat, long]);

  return <div id="map" style={{ width: "100%", height: "100vh" }}></div>;
};

export default DarkerHumanitarianMap;
